import {mapActions, mapGetters} from "vuex";
//mixins
import {dynamicHead} from '@/mixins/dynamic-head.js'
import AOS from 'aos';

import 'aos/dist/aos.css';
export default {
  name: "main-article",
  mixins: [dynamicHead],
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchArticle(this.$route.params.slug).then(()=>{
          this.setParams()
          this.setMeta()
        }).catch(error=>{
          if (error.status === 403) {
            this.$router.push({name: 'news'}).catch(()=>{console.log()})
          }
        })
      }
    },
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        this.crumbs[1].title = newVal.news
      }
    },
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
      updateCrumbs: false,
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
        {name: 'news', title: this.$t('news'), slug: ''},
        {name: 'article', title: '', slug: this.$route.params.slug}
      ],
    }
  },
  created() {
    this.fetchArticle(this.$route.params.slug).then(()=>{
      this.setParams()
      this.setMeta()
      this.crumbs[2].title = this.article.title
      this.updateCrumbs = true
    }).catch(error=>{
      if (error.status === 403) {
        this.$router.push({name: 'news'}).catch(()=>{console.log()})
      }
    })
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      article: 'news/article'
    }),

  },
  methods:{
    ...mapActions({
      fetchArticle: 'news/GET_ARTICLE',
    }),
    goBack(){
      history.back()
    },
    setParams(){
      this.params.title = this.article.metaTitle;
      this.params.description = this.article.metaDescription;
      this.params.keywords = this.article.metaKeywords;
      this.params.image = this.article.image;
    }
  }
}